@charset "utf-8";
@import "_variables";

@include pc {
  .sp {
    display: none !important;
  }
}
@include sp {
  .pc {
    display: none !important;
  }
}

* {
  margin: 0;
  padding: 0;
}
a {
  color: inherit;
  text-decoration: none;
  transition: opacity 0.3s;
  word-break: break-all;
  &:hover {
    opacity: 0.75;
  }
}
button {
  border: none;
  border-radius: 0;
  outline: 0;
  background: 0 0;
}
li {
  list-style: none;
}
img {
  border: none;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  touch-action: manipulation; //ダブルタップ禁止
}

body {
  margin: 0;
  padding: 0;
  color: #2c2c2c;
  background: #fff;
  font-family: "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif !important;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  line-height: calc(28 / 16);
  @include tb {
    font-size: calcTb(16);
  }
  @include sp {
    font-size: 12px;
    padding-top: 67px;
  }
  &.index.nav-open,
  &.seminar.nav-open,
  &.ikuboss.nav-open,
  &.company-region.nav-open,
  &.employee.nav-open,
  &.employee-index.nav-open,
  &.employee-voice.nav-open,
  &.employee-seminar.nav-open,
  &.employee-concept.nav-open {
    @include sp {
      padding-top: 185px;
    }
  }
  &.nav-open {
    @include sp {
      padding-top: 127px;
    }
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-10 {
  margin-top: 10px !important;
  @include tb {
    margin-top: calcTb(10) !important;
  }
  @include sp {
    margin-top: calcSp(10) !important;
  }
}
.mt-20 {
  margin-top: 20px !important;
  @include tb {
    margin-top: calcTb(20) !important;
  }
  @include sp {
    margin-top: calcSp(20) !important;
  }
}
.mt-30 {
  margin-top: 30px !important;
  @include tb {
    margin-top: calcTb(30) !important;
  }
  @include sp {
    margin-top: calcSp(30) !important;
  }
}
.mt-40 {
  margin-top: 40px !important;
  @include tb {
    margin-top: calcTb(40) !important;
  }
  @include sp {
    margin-top: calcSp(40) !important;
  }
}
.mt-50 {
  margin-top: 50px !important;
  @include tb {
    margin-top: calcTb(50) !important;
  }
  @include sp {
    margin-top: calcSp(50) !important;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-10 {
  margin-bottom: 10px !important;
  @include tb {
    margin-bottom: calcTb(10) !important;
  }
  @include sp {
    margin-bottom: calcSp(10) !important;
  }
}
.mb-20 {
  margin-bottom: 20px !important;
  @include tb {
    margin-bottom: calcTb(20) !important;
  }
  @include sp {
    margin-bottom: calcSp(20) !important;
  }
}
.mb-30 {
  margin-bottom: 30px !important;
  @include tb {
    margin-bottom: calcTb(30) !important;
  }
  @include sp {
    margin-bottom: calcSp(30) !important;
  }
}
.mb-40 {
  margin-bottom: 40px !important;
  @include tb {
    margin-bottom: calcTb(40) !important;
  }
  @include sp {
    margin-bottom: calcSp(40) !important;
  }
}
.mb-50 {
  margin-bottom: 50px !important;
  @include tb {
    margin-bottom: calcTb(50) !important;
  }
  @include sp {
    margin-bottom: calcSp(50) !important;
  }
}

.error-message {
  margin: 100px auto;
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  @include tb {
    margin: calcTb(100) auto;
    font-size: calcTb(42);
  }
  @include sp {
    margin: calcSp(100) auto;
    font-size: 26px;
  }
}

.text-center {
  text-align: center;
}

.logo-download {
  margin-top: 50px;
  @include tb {
    margin-top: calcTb(50);
  }
  @include sp {
    margin-top: calcSp(50);
  }
}

.size-110 {
  font-size: 110%;
}
.size-120 {
  font-size: 120%;
}
.size-130 {
  font-size: 130%;
}

.font-m {
  font-weight: 500 !important;
}
.font-b {
  font-weight: 600 !important;
}

.list-indent {
  li {
    padding-left: 1em;
    text-indent: -1em;
  }
  &-2 {
    li {
      margin-bottom: 0.5em;
      text-indent: -2em;
      padding-left: 2em;
    }
  }
}

a {
  &.link {
    color: #005fde;
    text-decoration: underline;
    .employee &,
    .employee-index &,
    .employee-concept &,
    .employee-voice & {
      color: #00aa55;
    }
    .library & {
      color: #ff7800;
    }
  }
  &.link--btn {
    padding: 0 30px;
    margin-top: 12px;
    min-width: 230px;
    box-sizing: border-box;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #005fde;
    background: linear-gradient(#ffffff, #f5f5f5);
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-decoration: none !important;
    border: 1px solid #005fde;
    text-decoration: none;
    @include tb {
      padding: 0 calcTb(30);
      margin-top: calcTb(12);
      min-width: calcTb(230);
      height: calcTb(40);
      font-size: calcTb(14);
    }
    @include sp {
      padding: 8px calcSp(25) 10px;
      height: auto;
      min-width: calcSp(350);
      border-radius: 50px;
      font-size: 11px;
      font-feature-settings: "palt";
    }
    .employee &,
    .employee-index &,
    .employee-concept &,
    .employee-voice & {
      border: 1px solid #00aa55;
      color: #00aa55;
    }
    .project &,
    .library & {
      border: 1px solid #ff7800;
      color: #ff7800;
    }
    .default & {
      border: 1px solid #c11920;
      color: #c11920;
    }
    &:hover {
      color: #fff;
      background: #005fde;
      opacity: 1;
      .employee &,
      .employee-index &,
      .employee-concept &,
      .employee-voice & {
        background: #00aa55;
      }
      .project &,
      .library & {
        background: #ff7800;
      }
      .default & {
        background: #c11920;
      }
    }
  }
  &.link--submit {
    margin: 20px auto 0;
    font-size: 20px;
    line-height: 50px;
    border-radius: 5px;
    display: block;
    background: linear-gradient(#ffffff, #f5f5f5);
    width: 347px;
    height: 50px;
    border: 0;
    text-indent: 0px;
    cursor: pointer;
    color: #005fde;
    border: 1px solid #005fde;
    .employee-voice & {
      color: #00aa55;
      border: 1px solid #00aa55;
    }
    .project & {
      color: #ff7800;
      border: 1px solid #ff7800;
    }
    .default & {
      color: #c11920;
      border: 1px solid #c11920;
    }
    @include tb {
      margin-top: calcTb(20);
      font-size: calcTb(20);
      line-height: calcTb(50);
      border-radius: calcTb(5);
      width: calcTb(347);
      height: calcTb(50);
    }
    @include sp {
      margin-top: 10px;
      width: 100%;
      font-size: 18px;
      line-height: 45px;
      height: 45px;
    }
    &:hover {
      background: #005fde;
      color: #fff;
      opacity: 1;
      .employee-voice & {
        background: #00aa55;
      }
      .project & {
        background: #ff7800;
      }
      .default & {
        background: #c11920;
      }
    }
  }
}

/* ----------------------------------------
	header
---------------------------------------- */
.l-header {
  position: relative;
  margin: auto;
  width: 100%;
  background: #fff;
  z-index: 100;
  &__inner {
    padding: 19px 0;
    margin: auto;
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include tb {
      padding: calcTb(19) 0;
      max-width: calcTb(1100);
    }
    @include sp {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 13px;
      z-index: 100;
      box-sizing: border-box;
      background: #fff;
    }
    .sp-menu-btn {
      display: none;
      @include sp {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 18px;
        display: block;
        width: 30px;
        height: 21px;
        z-index: 100;
        span {
          position: absolute;
          right: 0;
          width: 100%;
          height: 3px;
          border-radius: 2px;
          background: #c11920;
          transition:
            transform 0.3s,
            opacity 0.3s;
          &:nth-child(1) {
            top: 0;
          }
          &:nth-child(2) {
            top: 9px;
          }
          &:nth-child(3) {
            bottom: 0;
          }
        }
      }
    }
  }
  .header-search {
    @include sp {
      width: calcSp(566);
    }
  }

  //nav-open
  .nav-open & {
    @include sp {
      position: fixed;
      top: 0;
      height: 100dvh;
      overflow-y: scroll;
      background: rgba($color: #000000, $alpha: 0.15);
    }
    .l-header__inner {
      @include sp {
        position: absolute;
      }
    }
    .header-logo {
      @include sp {
        opacity: 0;
        visibility: hidden;
      }
    }
    .header-search {
      @include sp {
        position: absolute;
        opacity: 1;
        visibility: visible;
      }
    }
    .sp-menu-btn {
      span {
        &:nth-child(1) {
          top: 9px;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: translateX(50%);
        }
        &:nth-child(3) {
          bottom: 9px;
          transform: rotate(-45deg);
        }
      }
    }
    .sp-menu {
      @include sp {
        display: none;
      }
    }
    .header-gnav {
      @include sp {
        top: 67px;
      }
      & > ul {
        @include sp {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          max-height: 100dvh;
          z-index: 100;
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
    .header-gnav--secondary {
      @include sp {
        display: none;
      }
    }
  }
}

.sp-menu {
  display: none;
  background: #f2f0f0;
  @include sp {
    display: flex;
    li {
      width: 50%;
      font-size: calcSp(28);
      font-weight: 600;
      line-height: calcSp(27);
      box-sizing: border-box;
      & > a {
        display: flex;
        height: 60px;
        align-items: center;
        justify-content: center;
        @include sp {
          height: calcSp(120);
        }
      }
      &:nth-child(1) {
        .index &,
        .company &,
        .company-region &,
        .seminar &,
        .ikuboss & {
          background: #005fde;
          & > a {
            color: #fff;
          }
        }
        border-right: 2px solid #fff;
        & > a {
          .gnav-icon {
            position: relative;
            padding: calcSp(50) 0 0 calcSp(61);
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              width: calcSp(282);
              height: calcSp(78.6);
              background: url(../images/common/nav_icon_company_sp.svg) center / 100% no-repeat;
            }
          }
        }
      }
      &:nth-child(2) {
        .employee &,
        .employee-index &,
        .employee-concept &,
        .employee-voice &,
        .employee-seminar & {
          background: #00aa55;
          & > a {
            color: #fff;
          }
        }
        & > a {
          .gnav-icon {
            position: relative;
            padding: calcSp(50) calcSp(17) 0 calcSp(89);
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              width: calcSp(249);
              height: calcSp(78);
              background: url(../images/common/nav_icon_employee_sp.svg) center / 100% no-repeat;
            }
          }
        }
      }
    }
  }
}

.header {
  &-logo {
    img {
      display: block;
      width: 191px;
      @include tb {
        width: calcTb(191);
      }
      @include sp {
        width: 170px;
      }
    }
  }
  &-search {
    @include sp {
      position: fixed;
      top: 12px;
      left: 10px;
      opacity: 0;
      visibility: hidden;
      transition:
        opacity 0.3s,
        visibility 0.3s;
    }
    .gsc-control-cse,
    .gsc-control-searchbox-only {
      padding: 2px;
      background: #c11920;
      border-radius: 30px;
      overflow: hidden;
      @include sp {
        padding: 0;
        background: #fff;
        border-color: #c11920;
        border: 2px solid #c11920;
      }
    }
    form {
      &.gsc-search-box {
        margin-bottom: 0;
      }
      table {
        &.gsc-search-box {
          margin-bottom: 0;
          td {
            &.gsc-input {
              padding: 0;
            }
            &.gsc-search-button {
              margin: 0;
              cursor: pointer;
            }
            &.gsib_a {
              padding: 0 9px;
            }
          }
          .gsc-input-box {
            border-radius: 30px 0 0 30px;
            width: 226px;
            border: none;
            @include sp {
              width: 100%;
              height: 28px;
            }
            .gsst_a {
              display: block;
              .gscb_a {
                display: block;
              }
            }
          }
          .gsc-search-button-v2 {
            padding: 0 30px;
            background: none;
            border: none;
            @include tb {
              padding: 0 calcTb(30);
            }
            @include sp {
              padding: 0 10px;
            }
            svg {
              width: 25px;
              height: 22px;
              @include tb {
                width: calcTb(25);
                height: calcTb(22);
              }
              @include sp {
                fill: #c11920;
              }
            }
          }
        }
      }
    }
  }
  &-gnav {
    position: relative;
    background: #f2f0f0;
    & > ul {
      margin: auto;
      max-width: 1100px;
      display: flex;
      flex-direction: row-reverse;
      border-left: 2px solid #fff;
      box-sizing: border-box;
      @include tb {
        max-width: calcTb(1100);
      }
      @include sp {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-left: none;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition:
          opacity 0.3s,
          visibility 0.3s,
          transform 0.3s;
      }
      & > li {
        border-right: 2px solid #fff;
        font-size: 15px;
        width: 100%;
        line-height: 20px;
        font-weight: 600;
        text-align: center;
        @include tb {
          font-size: calcTb(15);
          line-height: calcTb(20);
        }
        @include sp {
          width: 50%;
          box-sizing: border-box;
          border-right: none;
        }
        &.primary-item {
          width: 189px;
          flex-shrink: 0;
          @include tb {
            width: calcTb(189);
          }
          @include sp {
            width: 100%;
            border-right: none;
          }
          & > a {
            @include sp {
              pointer-events: none;
              border-bottom: 2px solid #fff;
            }
          }
        }
        & > a {
          display: block;
          display: flex;
          height: 95px;
          align-items: center;
          justify-content: center;
          @include tb {
            height: calcTb(95);
          }
          @include sp {
            padding: 0 20px;
            height: 60px;
            color: #fff;
          }
          &:hover {
            opacity: 1;
          }
        }
        &:nth-last-child(1) {
          font-size: 22px;
          line-height: 27px;
          @include tb {
            font-size: calcTb(22);
            line-height: calcTb(27);
          }
          @include sp {
            font-size: calcSp(28);
            line-height: calcSp(27);
            background: #005fde;
          }
          .index &,
          .company &,
          .company-region &,
          .ikuboss & {
            background: #005fde;
            & > a {
              color: #fff;
            }
          }
          &:hover {
            background: #005fde;
            color: #fff;
          }
          .gnav-icon {
            padding: 31px 0 0 60px;
            position: relative;
            @include tb {
              padding: calcTb(31) 0 0 calcTb(60);
            }
            @include sp {
              padding: calcSp(50) 0 0 0;
            }
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              width: 213px;
              height: 57px;
              background: url(../images/common/nav_icon_company.svg) center / 100% no-repeat;
              @include tb {
                width: calcTb(213);
                height: calcTb(57);
              }
              @include sp {
                width: calcSp(218);
                height: calcSp(45);
                background: url(../images/common/nav_fukidashi_company_sp.svg) center / 100% no-repeat;
              }
            }
          }
        }
        &:nth-last-child(2) {
          font-size: 22px;
          line-height: 27px;
          @include tb {
            font-size: calcTb(22);
            line-height: calcTb(27);
          }
          @include sp {
            font-size: calcSp(28);
            line-height: calcSp(27);
            border-right: none;
            background: #00aa55;
          }
          .employee &,
          .employee-index &,
          .employee-concept &,
          .employee-voice & {
            background: #00aa55;
            & > a {
              color: #fff;
            }
          }
          &:hover {
            background: #00aa55;
            color: #fff;
          }
          .gnav-icon {
            padding: 31px 0 0 71px;
            position: relative;
            @include tb {
              padding: calcTb(31) 0 0 calcTb(71);
            }
            @include sp {
              padding: calcSp(50) 0 0 calcSp(20);
            }
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              width: 192px;
              height: 56px;
              background: url(../images/common/nav_icon_employee.svg) center / 100% no-repeat;
              @include tb {
                width: calcTb(192);
                height: calcTb(56);
              }
              @include sp {
                width: calcSp(172);
                height: calcSp(46);
                background: url(../images/common/nav_fukidashi_employee_sp.svg) center / 100% no-repeat;
              }
            }
          }
        }
        &:nth-last-child(3),
        &:nth-last-child(4) {
          &:hover {
            & > a {
              background: #f7be00;
              color: #fff;
              &::after {
                background: url(../images/common/nav_icon_arrow_hover.svg) center / 100% no-repeat;
                @include sp {
                  background: none;
                }
              }
            }
          }
          & > a {
            position: relative;
            @include sp {
              background: #f7be00;
              color: #fff;
              justify-content: flex-start;
              text-align: left;
              br {
                display: none;
              }
            }
            &::after {
              position: absolute;
              bottom: 12px;
              left: 50%;
              transform: translateX(-50%);
              content: "";
              width: 11px;
              height: 7px;
              background: url(../images/common/nav_icon_arrow.svg) center / 100% no-repeat;
              @include tb {
                bottom: calcTb(12);
                width: calcTb(11);
                height: calcTb(7);
              }
              @include sp {
                content: "＋";
                bottom: 50%;
                right: 20px;
                left: auto;
                transform: translateY(-50%);
                background-image: none;
              }
            }
          }
          &.js-hover {
            & > a {
              &::after {
                @include sp {
                  content: "ー";
                }
              }
            }
          }
        }
        &:nth-last-child(3) {
          .project & {
            background: #f7be00;
            pointer-events: none;
            @include sp {
              pointer-events: all;
            }
            & > a {
              color: #fff;
              &::after {
                background: url(../images/common/nav_icon_arrow_hover.svg) center / 100% no-repeat;
                @include sp {
                  background: none;
                }
              }
            }
          }
        }
        &:nth-last-child(4) {
          .library & {
            background: #f7be00;
            pointer-events: none;
            @include sp {
              pointer-events: all;
            }
            & > a {
              color: #fff;
              &::after {
                background: url(../images/common/nav_icon_arrow_hover.svg) center / 100% no-repeat;
                @include sp {
                  background: none;
                }
              }
            }
          }
        }
      }
    }
    &--secondary {
      width: 100%;
      .index &,
      .company &,
      .company-region &,
      .seminar &,
      .ikuboss & {
        display: block;
        background: #ddedff;
      }
      .employee &,
      .employee-index &,
      .employee-concept &,
      .employee-voice &,
      .employee-seminar & {
        display: block;
        background: #dff2e0;
      }
      & > ul {
        margin: auto;
        max-width: 1100px;
        display: none;
        grid-template-columns: 358px 358px 1fr;
        @include tb {
          max-width: calcTb(1100);
          grid-template-columns: calcTb(358) calcTb(358) 1fr;
        }
        @include sp {
          margin-top: 2px;
          grid-template-columns: 1fr 1fr 1fr;
        }
        & > li {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          @include tb {
            font-size: calcTb(15);
            line-height: calcTb(20);
          }
          @include sp {
            border-left: 2px solid #fff;
            font-size: calcSp(20);
            line-height: calcSp(32);
            font-feature-settings: "palt";
          }
          & > a {
            padding-bottom: 10px;
            position: relative;
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 56px;
            box-sizing: border-box;
            @include tb {
              padding-bottom: calcTb(10);
              height: calcTb(56);
            }
          }
          &.secondary-item {
            & > a {
              &::after {
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                content: "";
                width: 11px;
                height: 7px;
                @include tb {
                  bottom: calcTb(10);
                  width: calcTb(11);
                  height: calcTb(7);
                }
                .index &,
                .company &,
                .company-region &,
                .seminar &,
                .ikuboss & {
                  background: url(../images/common/nav_icon_arrow_company.svg) center / 100% no-repeat;
                }
                .employee &,
                .employee-index &,
                .employee-concept &,
                .employee-voice &,
                .employee-seminar &,
                .library &,
                .project &,
                .sitemap & {
                  background: url(../images/common/nav_icon_arrow_employee.svg) center / 100% no-repeat;
                }
              }
            }
          }
          &.js-hover {
            a {
              opacity: 1;
              background: #fff;
            }
          }
          &.secondary-item {
            & > a {
              @include sp {
                pointer-events: none;
              }
            }
          }
          &:first-child {
            border-left: none;
          }
          &:last-child {
            &:hover {
              a {
                opacity: 1;
                background: #fff;
              }
            }
          }
        }
      }
    }
    &__company {
      .index &,
      .company &,
      .company-region &,
      .seminar &,
      .ikuboss & {
        display: grid;
      }
      & > li {
        .company &,
        .company-region & {
          &:nth-child(1) {
            background: #fff;
            pointer-events: none;
            @include sp {
              background: inherit;
              pointer-events: all;
            }
          }
        }
        .ikuboss & {
          &:nth-child(2) {
            background: #fff;
            pointer-events: none;
            @include sp {
              background: inherit;
              pointer-events: all;
            }
          }
        }
        .seminar & {
          &:nth-child(3) {
            background: #fff;
            pointer-events: none;
          }
        }
      }
    }
    &__employee {
      .employee &,
      .employee-index &,
      .employee-concept &,
      .employee-voice &,
      .employee-seminar & {
        display: grid;
      }
      & > li {
        .employee &,
        .employee-concept & {
          &:nth-child(1) {
            background: #fff;
            pointer-events: none;
            @include sp {
              background: inherit;
              pointer-events: all;
            }
          }
        }
        .employee-voice & {
          &:nth-child(2) {
            background: #fff;
            pointer-events: none;
            @include sp {
              background: inherit;
              pointer-events: all;
            }
          }
        }
        .employee-seminar & {
          &:nth-child(3) {
            background: #fff;
            pointer-events: none;
          }
        }
      }
    }
    &--sub {
      padding: 30px 0;
      position: absolute;
      left: 0;
      width: 100%;
      top: 100%;
      opacity: 0;
      visibility: hidden;
      background: #fff;
      z-index: 1;
      transition:
        opacity 0.2s,
        visibility 0.2s;
      transition-delay: 0.2s;
      @include tb {
        padding: calcTb(30) 0;
      }
      @include sp {
        padding: 17px 0 0;
        transition: none;
        .primary-item & {
          display: none;
          position: static;
          padding: 0;
          background: #f2f0f0;
          opacity: 1;
          visibility: visible;
        }
      }
      &.active {
        opacity: 1;
        visibility: visible;
      }
      & > ul {
        margin: auto;
        max-width: 1100px;
        display: flex;
        flex-wrap: wrap;
        @include tb {
          max-width: calcTb(1100);
        }
        & > li {
          margin-left: 34px;
          padding-left: 38px;
          border-left: 2px solid #f2f0f0;
          @include tb {
            margin-left: calcTb(34);
            padding-left: calcTb(38);
          }
          @include sp {
            width: 100%;
            margin: 0;
            padding: 0;
            border-left: none;
          }
          &:first-child {
            border-left: none;
            margin-left: 0 !important;
            @include sp {
              border-top: 2px solid #ddedff;
              width: 100%;
              .employee &,
              .employee-index &,
              .employee-concept &,
              .employee-voice &,
              .employee-seminar &,
              .library &,
              .sitemap &,
              .project & {
                border-top: 2px solid #dff2e0;
              }
              .primary-item & {
                border-top: none;
              }
            }
          }
          &:last-child {
            & > a:last-child {
              @include sp {
                border-bottom: none !important;
              }
            }
          }
          & > a {
            position: relative;
            margin-bottom: 12px;
            padding: 0 20px;
            box-sizing: border-box;
            display: block;
            width: 290px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: left;
            border-radius: 10px;
            font-size: 12px;
            @include tb {
              margin-bottom: calcTb(12);
              padding: 0 calcTb(20);
              width: calcTb(290);
              height: calcTb(60);
              font-size: calcTb(12);
            }
            @include sp {
              margin-bottom: 0;
              width: 100%;
              height: 59px;
              border-radius: 0;
            }
            &:hover {
              opacity: 1;
              background: #ddedff;
            }
            &::after {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              content: "";
              width: 19px;
              height: 19px;
              background: url(../images/common/nav_icon_secandary_company_on.svg) center / 100% no-repeat;
              @include tb {
                right: calcTb(10);
                width: calcTb(19);
                height: calcTb(19);
              }
              .employee &,
              .employee-index &,
              .employee-concept &,
              .employee-voice &,
              .employee-seminar &,
              .library &,
              .project &,
              .sitemap & {
                background: url(../images/common/nav_icon_secandary_employee_on.svg) center / 100% no-repeat;
              }
              .primary-item & {
                background: url(../images/common/nav_icon_secandary_on.svg) center / 100% no-repeat;
                @include sp {
                  right: 0;
                  width: 7px;
                  height: 14px;
                  background: url(../images/common/nav_icon_secandary_sp.svg) center / 100% no-repeat;
                }
              }
            }
            .primary-item & {
              border: 2px solid #ffe693 !important;
              @include sp {
                margin: auto;
                width: calc(100% - 40px);
                border: none !important;
                border-bottom: 2px solid #fff !important;
              }
              &:hover {
                opacity: 1;
                background: #fff1c4 !important;
                @include sp {
                  background: #f2f0f0 !important;
                  .library &,
                  .project & {
                    background: inherit !important;
                  }
                }
              }
            }
            .index &,
            .company &,
            .company-region &,
            .seminar &,
            .ikuboss & {
              border: 2px solid #ddedff;
              @include sp {
                border-top: none !important;
                border-left: none !important;
                border-right: none !important;
              }
            }
            .employee &,
            .employee-index &,
            .employee-concept &,
            .employee-voice &,
            .employee-seminar &,
            .library &,
            .project &,
            .sitemap & {
              border: 2px solid #dff2e0;
              @include sp {
                border-top: none;
                border-left: none;
                border-right: none;
              }
              &:hover {
                opacity: 1;
                background: #dff2e0;
              }
            }
          }
        }
        &.col4 {
          justify-content: center;
          & > li {
            margin-left: 20px;
            padding-left: 0;
            border-left: none;
            @include tb {
              margin-left: calcTb(20);
            }
            @include sp {
              margin-left: 0;
            }
            a {
              width: 260px;
              height: 70px;
              font-size: 14px;
              @include tb {
                width: calcTb(260);
                height: calcTb(70);
                font-size: calcTb(14);
              }
              @include sp {
                width: 100%;
                height: 59px;
                border-radius: 0;
              }
              br {
                @include sp {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ----------------------------------------
	main
---------------------------------------- */

.l-main {
  overflow: hidden;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.15);
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.5s,
      visibility 0.5s;
    transition-delay: 0.2s;
    z-index: 1;
    @include sp {
      transition: none;
    }
  }
  &.nav-active {
    position: relative;
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
  .nav-open & {
    &::after {
      @include sp {
        display: none;
      }
    }
  }
}

.js-mainSlider {
  position: relative;
  padding: 45px 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  @include tb {
    padding: calcTb(45) 0;
  }
  @include sp {
    padding: 18px 0 25px;
  }
  .swiper-slide {
    width: 614px;
    @include tb {
      width: calcTb(614);
    }
    @include sp {
      width: 307px;
    }
  }
  .swiper-pagination {
    bottom: 0 !important;
    &-bullet {
      margin: 0 5px !important;
      width: 10px;
      height: 10px;
      @include tb {
        margin: 0 calcTb(5) !important;
        width: calcTb(10);
        height: calcTb(10);
      }
      @include sp {
        width: 7px;
        height: 7px;
      }
      &-active {
        background: #005fde;
        .employee-index & {
          background: #00aa55;
        }
      }
    }
  }
}

.l-flex-container {
  padding: 45px 0;
  margin: auto;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include tb {
    padding: calcTb(45) 0;
    max-width: calcTb(1000);
  }
  @include sp {
    padding: calcSp(58) 20px;
  }
}

.l-container {
  padding: 45px 0;
  margin: auto;
  max-width: 970px;
  @include tb {
    padding: calcTb(45) 0;
    max-width: calcTb(970);
  }
  @include sp {
    padding: calcSp(58) 20px;
  }
}

.l-contents {
  max-width: 720px;
  width: 100%;
  @include tb {
    max-width: calcTb(720);
  }
}

.l-contents,
.l-container {
  h1 {
    margin-bottom: 50px;
    padding-bottom: 10px;
    position: relative;
    font-size: 32px;
    font-weight: 600;
    font-feature-settings: "palt";
    line-height: 48px;
    @include tb {
      margin-bottom: calcTb(50);
      padding-bottom: calcTb(10);
      font-size: calcTb(32);
      line-height: calcTb(48);
    }
    @include sp {
      margin-bottom: calcSp(50);
      padding-bottom: 10px;
      font-size: 21px;
      line-height: 1.3;
    }
    span {
      display: block;
      font-size: 50%;
      line-height: 1.3;
      @include sp {
        margin-bottom: 5px;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background: #005fde;
      .employee &,
      .employee-index &,
      .employee-concept &,
      .employee-voice & {
        background: #00aa55;
      }
      .library &,
      .project & {
        background: #f7be00;
      }
      .sitemap &,
      .default & {
        background: #c11920;
      }
    }
    &.seminar-main {
      padding-bottom: 0;
      &::after {
        display: none;
      }
    }
  }
  h2 {
    margin-bottom: 30px;
    padding-left: 15px;
    position: relative;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    font-feature-settings: "palt";
    border-left: solid 12px #4b7ee8;
    @include tb {
      margin-bottom: calcTb(30);
      padding-left: calcTb(15);
      font-size: calcTb(24);
      line-height: calcTb(32);
    }
    @include sp {
      margin-bottom: 15px;
      padding-left: 6px;
      font-size: 16px;
      line-height: 22px;
      border-left: solid 8px #4b7ee8;
    }
    .employee &,
    .employee-index &,
    .employee-concept &,
    .employee-voice & {
      border-left: solid 12px #00aa55;
      @include sp {
        border-left: solid 8px #00aa55;
      }
    }
    .library &,
    .project & {
      border-left: solid 12px #ff7800;
      @include sp {
        border-left: solid 8px #ff7800;
      }
    }
    .sitemap &,
    .default & {
      border-left: solid 12px #c11920;
      @include sp {
        border-left: solid 8px #c11920;
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: -8px;
      top: 0;
      width: 4px;
      height: 100%;
      background: #fff;
      @include sp {
        left: -5px;
        width: 2px;
      }
    }
  }
  h3 {
    margin-bottom: 0.5em;
    color: #4b7ee8;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    font-feature-settings: "palt";
    @include tb {
      font-size: calcTb(20);
      line-height: calcTb(28);
    }
    @include sp {
      font-size: 15px;
      line-height: 20px;
    }
    .employee &,
    .employee-index &,
    .employee-concept &,
    .employee-voice & {
      color: #00aa55;
    }
    .library &,
    .project & {
      color: #ff7800;
    }
  }
  section {
    margin-bottom: 70px;
    @include tb {
      margin-bottom: calcTb(70);
    }
    @include sp {
      margin-bottom: calcSp(70);
    }
    p {
      margin-bottom: 0.5em;
      &.note {
        font-size: 14px;
        @include sp {
          font-size: 12px;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  figure {
    margin-top: 1em;
    figcaption {
      margin-top: 0.5em;
    }
  }
  .box {
    .row {
      margin-bottom: 30px;
      padding: 20px 30px;
      background-color: #ddedff;
      border-radius: 10px;
      @include tb {
        margin-bottom: calcTb(30);
        padding: calcTb(20) calcTb(30);
      }
      @include sp {
        margin-bottom: calcSp(30);
        padding: 15px 12px;
      }
      .employee &,
      .employee-index &,
      .employee-concept &,
      .employee-voice & {
        background-color: #dff2e0;
      }
      .library & {
        background-color: #f2f0f0;
      }
    }
    &-flex {
      display: flex;
      flex-wrap: wrap;
      align-items: normal;
      justify-content: space-between;
      &--nowrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: flex-start;
      }
      &-center {
        display: flex;
        flex-wrap: wrap;
        align-items: center;  
        justify-content: center;
      }  
      .col2 {
        margin-top: 30px;
        padding: 20px 30px;
        box-sizing: border-box;
        width: calc(50% - 15px);
        font-size: 14px;
        background-color: #ddedff;
        border-radius: 10px;
        @include tb {
          margin-top: calcTb(30);
          padding: calcTb(20) calcTb(30);
          width: calc(50% - calcTb(15));
          font-size: calcTb(14);
        }
        @include sp {
          margin-top: calcSp(30);
          padding: 15px 12px;
          width: 100%;
          font-size: 12px;
        }
        .employee &,
        .employee-index &,
        .employee-concept &,
        .employee-voice & {
          background-color: #dff2e0;
        }
        .library &,
        .project & {
          background-color: #fff1c4;
        }
        .seminar & {
          background-color: #f4f4f4;
        }
      }
      .col3 {
        margin-top: 30px;
        padding: 20px 30px;
        box-sizing: border-box;
        width: calc(33.333% - 15px);
        background-color: #ddedff;
        border-radius: 10px;
        font-size: 14px;
        @include tb {
          margin-top: calcTb(30);
          padding: calcTb(20) calcTb(30);
          width: calc(33.333% - calcTb(15));
        }
        @include sp {
          margin-top: calcSp(30);
          padding: 8px;
          border-radius: 5px;
          width: calc(33.333% - calcSp(15));
          font-size: 12px;
        }
        .employee &,
        .employee-index &,
        .employee-concept &,
        .employee-voice & {
          background-color: #dff2e0;
        }
        .library &,
        .project & {
          background-color: #fff1c4;
        }
        .seminar & {
          background-color: #f4f4f4;
        }
      }
    }
  }
  .img {
    &-flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      @include tb {
        margin-top: calcTb(20);
      }
      .col2 {
        margin-top: 20px;
        width: calc(50% - 10px);
        @include tb {
          margin-top: calcTb(20);
          width: calc(50% - calcTb(10));
        }
        @include sp {
          margin-top: 10px;
          width: calc(50% - 5px);
        }
      }
      .col3 {
        margin-top: 10px;
        width: calc(33.333% - 10px);
        @include tb {
          margin-top: calcTb(10);
          width: calc(33.333% - calcTb(10));
        }
        @include sp {
          margin-top: 10px;
          width: calc(50% - 5px);
        }
      }
    }
    &-float {
      &--right {
        &::after {
          content: "";
          display: block;
          clear: both;
        }
        img {
          margin: 0.5em 0 0.5em 1em;
          float: right;
          width: 35%;
        }
      }
      &--left {
        &::after {
          content: "";
          display: block;
          clear: both;
        }
        img {
          margin: 0.5em 0 0.5em 1em;
          float: left;
          width: 35%;
        }
      }
    }
  }

  .list-disc {
    margin-left: 1em;
    display: list-item;
    li {
      list-style: disc;
    }
  }

  .download-box,
  .library-download {
    &__wrap {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include tb {
        margin-top: calcTb(30);
      }
      @include sp {
        margin-top: calcSp(50);
      }
      li {
        margin-bottom: 30px;
        padding: 20px;
        width: calc(50% - 25px);
        background: #f2f0f0;
        border-radius: 14px;
        text-align: center;
        box-sizing: border-box;
        @include tb {
          margin-bottom: calcTb(30);
          padding: calcTb(30) calcTb(20);
          width: calc(50% - calcTb(25));
          border-radius: calcTb(14);
        }
        @include sp {
          margin-bottom: 20px;
          padding: 15px 10px;
          width: calc(50% - 7px);
          border-radius: 7px;
        }
        a.link--btn {
          @include sp {
            min-width: calcSp(230);
            height: 25px;
          }
        }
      }
    }
    &-title {
      margin: 0 0 10px !important;
      font-size: 18px;
      font-weight: 500;
      @include tb {
        margin: 0 0 calcTb(10) !important;
        font-size: calcTb(18);
      }
      @include sp {
        margin: 0 !important;
        font-size: 14px;
      }
    }
    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 273px;
      @include tb {
        height: calcTb(273);
      }
      @include sp {
        height: auto;
      }
      img {
        max-height: 100%;
        @include sp {
          max-height: none;
          max-width: 80%;
        }
      }
    }
    &-text {
      margin-top: 1em;
      margin-bottom: 0 !important;
      font-size: 14px;
      line-height: 1.7;
      @include tb {
        font-size: calcTb(14);
      }
      @include sp {
        font-size: 11px;
      }
    }
    a {
      &.link--btn {
        min-width: 200px !important;
        @include tb {
          min-width: calcTb(200);
        }
        @include sp {
          min-width: 100% !important;
        }
      }
    }
  }
}

.l-side {
  width: 210px;
  @include tb {
    width: calcTb(210);
  }
  @include sp {
    display: none;
  }
  aside {
    &.fixed {
      position: fixed;
      width: 210px;
      @include tb {
        width: calcTb(210);
      }
    }
    .side-title {
      height: 60px;
      border-radius: 10px 10px 0 0;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      line-height: 1.5;
      font-weight: 500;
      text-align: center;
      @include tb {
        height: calcTb(60);
        border-radius: calcTb(10) calcTb(10) 0 0;
        font-size: calcTb(15);
      }
      .company &,
      .company-region &,
      .ikuboss & {
        background: #005fde;
      }
      .employee &,
      .employee-index &,
      .employee-concept &,
      .employee-voice & {
        background: #00aa55;
      }
      .library &,
      .project & {
        background: #f7be00;
      }
    }
    ul {
      li {
        a {
          position: relative;
          padding-left: 1em;
          display: flex;
          align-items: center;
          height: 64px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          background: #f2f0f0;
          border-bottom: 2px dashed #fff;
          @include tb {
            height: calcTb(64);
            font-size: calcTb(14);
            line-height: calcTb(20);
          }
          &::after {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            width: 19px;
            height: 19px;
            background: url(../images/common/side_icon_company.svg) center / 100% no-repeat;
            @include tb {
              right: calcTb(10);
              width: calcTb(19);
              height: calcTb(19);
            }
            .employee &,
            .employee-index &,
            .employee-concept &,
            .employee-voice & {
              background: url(../images/common/side_icon_employee.svg) center / 100% no-repeat;
            }
            .library &,
            .project & {
              background: url(../images/common/side_icon.svg) center / 100% no-repeat;
            }
          }
          &:hover {
            opacity: 1;
            &::after {
              background: url(../images/common/side_icon_company_on.svg) center / 100% no-repeat;
              .employee &,
              .employee-index &,
              .employee-concept &,
              .employee-voice & {
                background: url(../images/common/side_icon_employee_on.svg) center / 100% no-repeat;
              }
              .library &,
              .project & {
                background: url(../images/common/side_icon_on.svg) center / 100% no-repeat;
              }
            }
          }
        }
        &.on {
          a {
            background: #ddedff;
            .employee &,
            .employee-index &,
            .employee-concept &,
            .employee-voice & {
              background: #dff2e0;
            }
            .library &,
            .project & {
              background: #fff1c4;
            }
            &::after {
              background: url(../images/common/side_icon_company_on.svg) center / 100% no-repeat;
              .employee &,
              .employee-index &,
              .employee-concept &,
              .employee-voice & {
                background: url(../images/common/side_icon_employee_on.svg) center / 100% no-repeat;
              }
              .library &,
              .project & {
                background: url(../images/common/side_icon_on.svg) center / 100% no-repeat;
              }
            }
          }
        }
        &:last-child {
          a {
            border-bottom: none;
            border-radius: 0 0 10px 10px;
          }
        }
      }
    }
  }
}

.link-section {
  position: relative;
  padding: 40px 0;
  margin: 110px auto 0;
  background: #f4f4f4;
  @include tb {
    padding: calcTb(40) 0;
    margin: calcTb(110) auto 0;
  }
  @include sp {
    padding: calcSp(80) 0 calcSp(70);
    margin: calcSp(88) auto 0;
  }
  .index &,
  .employee-index & {
    padding: 69px 0 96px;
    background: none;
    @include tb {
      padding: calcTb(69) 0 calcTb(96);
    }
    @include sp {
      padding: calcSp(90) 0 calcSp(70);
    }
  }
  .employee &,
  .employee-concept &,
  .employee-voice & {
    padding-top: 40px;
    margin: 78px auto 0;
    @include tb {
      padding-top: calcTb(40);
      margin: calcTb(78) auto 0;
    }
    @include sp {
      margin: calcSp(88) auto 0;
    }
  }
  .index &,
  .employee-index & {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: calc(50% + 550px);
      height: 100%;
      background: #ddedff;
      z-index: -1;
      border-radius: 0 46px 46px 0;
      @include tb {
        width: calc(50% + calcTb(550));
        border-radius: 0 calcTb(46) calcTb(46) 0;
      }
      @include sp {
        width: calcSp(687);
        border-radius: 0 calcSp(46) calcSp(46) 0;
      }
    }
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -205px;
      transform: translateX(504px);
      width: 336px;
      height: 402px;
      background: url(../images/index/parts_bg5.svg) center / 100% no-repeat;
      mix-blend-mode: multiply;
      @include tb {
        bottom: calcTb(-205);
        transform: translateX(calcTb(504));
        width: calcTb(336);
        height: calcTb(402);
      }
      @include sp {
        left: calcSp(555);
        bottom: calcSp(-100);
        transform: none;
        width: calcSp(239);
        height: calcSp(251);
        background: url(../images/index/parts_bg4.svg) center / 100% no-repeat;
      }
    }
  }
  .employee-index & {
    &::before {
      background: #dff2e0;
    }
    &::after {
      background: url(../images/employee/parts_bg4.svg) center / 100% no-repeat;
      @include sp {
        left: calcSp(555);
        bottom: calcSp(-100);
        transform: none;
        width: calcSp(239);
        height: calcSp(251);
        background: url(../images/index/parts_bg4.svg) center / 100% no-repeat;
      }
    }
  }

  h2 {
    text-align: center;
    @include sp {
      font-size: 21px;
    }
  }
  &__sns {
    margin-top: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tb {
      margin-top: calcTb(17);
    }
    li {
      margin: 0 7px;
    }
  }
}

.js-bnrSlider {
  padding-bottom: 32px;
  position: relative;
  margin: 42px auto 0;
  max-width: 1140px;
  overflow: hidden;
  @include tb {
    padding-bottom: calcTb(32);
    margin: calcTb(42) auto 0;
    max-width: calcTb(1140);
  }
  @include sp {
    display: none;
  }
  .swiper-slide {
    width: 210px;
    text-align: center;
    @include tb {
      width: calcTb(210);
    }
  }
  .swiper-pagination {
    bottom: 0 !important;
    &-bullet {
      margin: 0 5px !important;
      width: 10px;
      height: 10px;
      @include tb {
        margin: 0 calcTb(5) !important;
        width: calcTb(10);
        height: calcTb(10);
      }
      &-active {
        background: #005fde;
        .employee &,
        .employee-index &,
        .employee-seminar &,
        .employee-voice & {
          background: #00aa55;
        }
        .default &,
        .sitemap & {
          background: #c11920;
        }
        .project &,
        .library & {
          background: #f7be00;
        }
      }
    }
  }
}

.utilityNav {
  position: relative;
  padding: 73px 0 53px;
  margin: auto;
  max-width: 1100px;
  @include tb {
    padding: calcTb(73) 0 calcTb(53);
    max-width: calcTb(1100);
  }
  @include sp {
    padding: calcSp(130) 0 0;
  }
  .index & {
    &::after {
      @include sp {
        position: absolute;
        top: calcSp(-109);
        left: calcSp(146);
        content: "";
        width: calcSp(100);
        height: calcSp(206);
        background: url(../images/index/parts_person1_sp.svg) center / 100% no-repeat;
      }
    }
  }
  .employee-index & {
    &::after {
      position: absolute;
      top: -41px;
      left: 0;
      content: "";
      width: 109px;
      height: 188px;
      background: url(../images/employee/parts_bg5.svg) center / 100% no-repeat;
      @include tb {
        top: calcTb(-41);
        width: calcTb(109);
        height: calcTb(188);
      }
      @include sp {
        top: calcSp(-75);
        left: calcSp(111);
        width: calcSp(92);
        height: calcSp(158);
      }
    }
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    @include sp {
      margin: auto;
      width: calcSp(630);
      display: block;
      text-align: center;
    }
    li {
      position: relative;
      margin-left: 28px;
      padding-left: 28px;
      font-weight: 500;
      @include tb {
        margin-left: calcTb(28);
        padding-left: calcTb(28);
      }
      @include sp {
        margin-left: 0;
        margin-bottom: 15px;
        padding-left: 0;
        padding-bottom: 15px;
        font-weight: 400;
      }
      &:first-child {
        margin-left: 0;
        padding-left: 0;
        &::after {
          display: none;
          @include sp {
            display: block;
          }
        }
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 3px;
        height: 100%;
        border-radius: 2px;
        background: #ddedff;
        @include tb {
          width: calcTb(3);
        }
        @include sp {
          top: auto;
          bottom: 0;
          width: 100%;
          height: 2px;
        }
        .employee &,
        .employee-index &,
        .employee-concept &,
        .employee-voice &,
        .employee-seminar & {
          background: #dff2e0;
        }
        .library &,
        .project & {
          background: #fff1c4;
        }
        .sitemap &,
        .default & {
          background: #f9e8e8;
        }
      }
    }
  }
  p {
    margin-top: 40px;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    @include tb {
      margin-top: calcTb(40);
      font-size: calcTb(14);
    }
    @include sp {
      padding: calcSp(50) 0 0;
      background-color: #f2f0f0;
      font-size: 10px;
    }
  }
}

.faq-nav,
.download-nav {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include tb {
    margin-bottom: calcTb(50);
  }
  @include sp {
    margin-bottom: calcSp(50);
  }
  li {
    margin: 0 10px;
    @include sp {
      margin: 0 3px;
    }
  }
}
.faq-list {
  &-item {
    &-answer {
      display: none;
      padding: 10px 20px 10px 42px;
      background: #ddedff;
      border-radius: 0 0 8px 8px;
      @include tb {
        padding: calcTb(10) calcTb(20) calcTb(10) calcTb(42);
        border-radius: 0 0 calcTb(8) calcTb(8);
      }
      .employee &,
      .employee-index &,
      .employee-concept &,
      .employee-voice & {
        background: #dff2e0;
        a {
          color: #00aa55;
        }
      }
      .project &,
      .library & {
        background: #fff1c4;
        a {
          color: #ff7800;
        }
      }
      .default & {
        background: #f9e8e8;
        a {
          color: #c11920;
        }
      }
      p {
        margin-bottom: 0;
      }
      a {
        color: #005fde;
      }
    }
  }
  li {
    margin-bottom: 10px;
    h3 {
      margin-bottom: 10px;
      position: relative;
      padding: 10px 20px 10px 42px;
      background: #f2f0f0;
      color: #2c2c2c !important;
      font-size: 16px;
      border-radius: 8px;
      cursor: pointer;
      @include tb {
        margin-bottom: calcTb(10);
        padding: calcTb(10) calcTb(20) calcTb(10) calcTb(42);
        font-size: calcTb(16);
        border-radius: calcTb(8);
      }
      @include sp {
        font-size: 14px;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: 1em;
        transform: translateY(-50%);
        content: "";
        width: 11px;
        height: 7px;
        background: url(../images/common/nav_icon_arrow_company.svg) center / 100% no-repeat;
        .employee &,
        .employee-index &,
        .employee-concept &,
        .employee-voice & {
          background: url(../images/common/nav_icon_arrow_employee.svg) center / 100% no-repeat;
        }
        .project &,
        .library & {
          background: url(../images/common/nav_icon_arrow_project.svg) center / 100% no-repeat;
        }
        .default & {
          background: url(../images/common/nav_icon_arrow_default.svg) center / 100% no-repeat;
        }
      }
    }
  }
}

//フォームパーツ
form {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea,
  select {
    border-color: #dadada;
    background-color: #edf2fd;
  }
  .employee-voice & {
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    textarea {
      border-color: #dadada;
      background-color: #dff2e0;
    }
  }
  .project & {
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    textarea {
      border-color: #dadada;
      background-color: #fff1c4;
    }
  }
  .default & {
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    textarea {
      border-color: #dadada;
      background-color: #efefef;
    }
  }

  dl {
    display: grid;
    grid-template-columns: 252px 1fr;
    border-top: 1px solid #dadada;
    font-size: 14px;
    @include tb {
      grid-template-columns: calcTb(252) 1fr;
      font-size: calcTb(14);
    }
    @include sp {
      display: block;
      font-size: 13px;
    }
    dt {
      padding: 20px 26px 22px 15px;
      line-height: 1.3;
      box-sizing: border-box;
      @include tb {
        padding: calcTb(20) calcTb(26) calcTb(22) calcTb(15);
      }
      @include sp {
        padding: 14px 26px 0 0;
      }
      span {
        &.required {
          padding: 3px 6px;
          color: #fff;
          background-color: #ff0000;
          border-radius: 2px;
          float: right;
          font-size: 11px;
          @include tb {
            padding: calcTb(3) calcTb(6);
            font-size: calcTb(11);
          }
          @include sp {
            margin-left: 1em;
            padding: 2px 3px;
            font-size: 10px;
            float: none;
          }
        }
        &.comment,
        &.twitter {
          margin-right: 0.5em;
          padding: 3px 6px;
          color: #ff0000;
          background-color: #fff;
          border: solid #ff0000 1px;
          border-radius: 3px;
          float: none;
          display: inline-block;
          margin-bottom: 7px;
          font-size: 11px;
          @include tb {
            padding: calcTb(3) calcTb(6);
            font-size: calcTb(11);
          }
          @include sp {
            padding: 2px 3px;
            font-size: 10px;
          }
        }
        &.twitter {
          color: #009bc7;
          border: solid #009bc7 1px;
        }
      }
    }
    dd {
      width: 100%;
      padding: 14px 0;
      @include tb {
        padding: calcTb(14) 0;
      }
      @include sp {
        padding: 0.5em 0 14px;
        border-top: none;
      }
      input[type="text"],
      input[type="tel"],
      input[type="email"] {
        width: 100%;
        border: 1px solid #dadada;
        box-sizing: border-box;
        height: 30px;
        line-height: 30px;
        border-radius: 3px;
        padding: 0 15px;
        @include tb {
          height: calcTb(30);
          line-height: calcTb(30);
          border-radius: calcTb(3);
          padding: 0 calcTb(15);
        }
      }
      input[type="file"] {
        margin-bottom: 1em;
      }
      select {
        width: 170px;
        border: 1px solid #dadada;
        box-sizing: border-box;
        height: 30px;
        border-radius: 3px;
        padding: 4px 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        vertical-align: middle;
        background: url(../images/form/dropdown.png) no-repeat 150px center;
        background-size: 10px;
        @include tb {
          height: calcTb(30);
          padding: calcTb(4) calcTb(15);
          background-size: calcTb(10);
        }
        @include sp {
          padding: 4px 15px;
          width: 170px;
          height: 30px;
          background-size: 10px;
        }
      }
      textarea {
        width: 100%;
        border: 1px solid #dadada;
        box-sizing: border-box;
        min-height: 200px;
        border-radius: 3px;
        padding: 6px 15px 15px;
      }
      label {
        margin-right: 20px;
        @include tb {
          margin-right: calcTb(20);
        }
        @include sp {
          margin-right: 0;
          font-size: 12px;
        }
      }
      p {
        margin: 0.5em 0 0 !important;
      }
      img {
        margin-bottom: 15px;
      }
      .wordCount {
        margin-top: 0 !important;
        span {
          margin: 0 5px;
          text-align: right;
          font-size: 120%;
        }
      }
    }
    .note {
      color: #ff0000;
      font-size: 12px !important;
    }
  }

  .submit {
    margin: 20px auto 0;
    font-size: 20px;
    line-height: 50px;
    border-radius: 5px;
    display: block;
    background: linear-gradient(#ffffff, #f5f5f5);
    width: 347px;
    height: 50px;
    border: 0;
    text-indent: 0px;
    cursor: pointer;
    color: #005fde;
    border: 1px solid #005fde;
    .employee-voice & {
      color: #00aa55;
      border: 1px solid #00aa55;
    }
    .project & {
      color: #ff7800;
      border: 1px solid #ff7800;
    }
    .default & {
      color: #c11920;
      border: 1px solid #c11920;
    }
    @include tb {
      margin-top: calcTb(20);
      font-size: calcTb(20);
      line-height: calcTb(50);
      border-radius: calcTb(5);
      width: calcTb(347);
      height: calcTb(50);
    }
    @include sp {
      margin-top: 10px;
      width: 100%;
      font-size: 18px;
      line-height: 45px;
      height: 45px;
    }
    &:hover {
      background: #005fde;
      color: #fff;
      .employee-voice & {
        background: #00aa55;
      }
      .project & {
        background: #ff7800;
      }
      .default & {
        background: #c11920;
      }
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }
  .back {
    max-width: 200px;
    padding: 0 20px;
    font-size: 18px;
    line-height: 40px;
    border-radius: 5px;
    display: block;
    margin: 15px auto;
    background: linear-gradient(#ffffff, #f5f5f5);
    height: 40px;
    border: 0;
    text-indent: 0px;
    cursor: pointer;
    color: #005fde;
    border: 1px solid #005fde;
    opacity: 0.5;
    text-align: center;
    .employee-voice & {
      color: #00aa55;
      border: 1px solid #00aa55;
    }
    @include tb {
      padding: 0 calcTb(20);
      font-size: calcTb(18);
      line-height: calcTb(40);
      border-radius: calcTb(5);
      margin: calcTb(15) auto;
      max-width: calcTb(200);
      height: calcTb(40);
    }
    @include sp {
      max-width: 200px;
      font-size: 18px;
      line-height: 45px;
      height: 45px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .logo-download {
    margin-top: 50px;
    @include sp {
      margin-top: 20px;
    }
  }
}

.pagination {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  @include tb {
    margin-top: calcTb(70);
  }
  @include sp {
    margin-top: calcSp(70);
    display: block;
  }
  & > a {
    color: #00aa55;
    font-size: 13px;
    @include tb {
      font-size: calcTb(13);
    }
    @include sp {
      font-size: 12px;
    }
    .company &,
    .ikuboss & {
      color: #005fde;
    }
    .project & {
      color: #ff7800;
    }
    .default & {
      color: #c11920;
    }

    &.prev {
      margin-right: 1em;
      @include sp {
        margin: 0 auto 10px;
        display: block;
        text-align: center;
      }
    }
    &.next {
      margin-left: 1em;
      @include sp {
        margin: 0 auto 20px;
        display: block;
        text-align: center;
      }
    }
  }
  ul {
    margin-bottom: 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include tb {
      margin-bottom: calcTb(45);
    }
    @include sp {
      margin: auto;
      width: 90%;
    }
    li {
      @include sp {
        margin-bottom: 5px;
      }
      a,
      span {
        margin: 0 5px;
        width: 2.5em;
        border: solid 1px #00aa55;
        color: #00aa55;
        border-radius: 4px;
        text-align: center;
        font-size: 13px;
        @include tb {
          margin: 0 calcTb(5);
          border-radius: calcTb(4);
          font-size: calcTb(13);
        }
        @include sp {
          margin: 0 3px;
          font-size: 11px;
        }
        .company &,
        .ikuboss & {
          border: solid 1px #005fde;
          color: #005fde;
        }
        .project & {
          border: solid 1px #ff7800;
          color: #ff7800;
        }
        .default & {
          border: solid 1px #c11920;
          color: #c11920;
        }
      }
      a {
        display: block;
      }
      span {
        display: block;
        background: #00aa55;
        color: #fff;
        .company &,
        .ikuboss & {
          background: #005fde;
          color: #fff;
        }
        .project & {
          background: #ff7800;
          color: #fff;
        }
        .default & {
          background: #c11920;
          color: #fff;
        }
      }
    }
  }
}

.img-contact {
  height: 16px;
  @include tb {
    height: calcTb(16);
  }
  @include sp {
    height: 12px;
  }
}

.movie {
  &__wrap {
    margin-bottom: 1em;
    position: relative;
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      cursor: pointer;
      z-index: 5;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 130px;
        transform: translate(-50%, -50%);
        @include sp {
          width: 20vw;
        }
      }
    }
  }
  &-info {
    margin-top: 30px;
    @include tb {
      margin-top: calcTb(30);
    }
    @include sp {
      margin-top: calcSp(30);
    }
    h2 {
      padding-left: 0;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
      border-left: none !important;
      border-bottom: 1px solid;
    }
    ul {
      li {
        margin-bottom: 0.5em;
      }
    }
  }
}
#modal {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
  width: 100%;
  height: 100%;
  transition: .5s;
  z-index: 101;
  &.js-open {
    visibility: visible;
    opacity: 1;
  }
  .js-movieModal__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 80%;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    transform: translate(-50%,-50%);
    @include sp {
      max-width: 96%;
    }
    .js-movieModal__inner__movie {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      transform: translate(-50%, -50%);
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .js-movieModal__close {
    position: absolute;
    top: -70px;
    right: 0;
    width: 60px;
    height: 60px;
    cursor: pointer;
    @include sp {
      top: calcSp(-70);
      width: calcSp(60);
      height: calcSp(60);
    }
    span {
      &:first-child,&:last-child {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: 60px;
        height: 2px;
        background-color: #fff;
        transform-origin: initial;
        @include sp {
          width: calcSp(60);
        }
      }
      &:first-child {
        transform: translate(0,-50%) rotate(45deg);
      }
      &:last-child {
        transform: translate(0,-50%) rotate(-45deg);
      }
    }
  }
}

/* ----------------------------------------
	footer
---------------------------------------- */

.l-footer {
  padding: 50px 0 30px;
  @include tb {
    padding: calcTb(50) 0 calcTb(30);
  }
  @include sp {
    padding: 35px 0 20px;
    background-color: #f2f0f0;
  }
  &__mhlw {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    @include tb {
      font-size: calcTb(14);
      line-height: calcTb(22);
    }
    @include sp {
      font-size: 10px;
      line-height: 15px;
    }
    .mhlw-logo {
      margin-right: 77px;
      width: 256px;
      @include tb {
        margin-right: calcTb(77);
        width: calcTb(256);
      }
      @include sp {
        margin-right: calcSp(45);
        width: calcSp(256);
      }
    }
  }
}
